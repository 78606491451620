import React from 'react';
import styled, { css } from 'styled-components';
import { EntityComponent, Page, Post } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { SliceLayoutColorStrip } from '../slice-layouts';
import { PostBannerLayout } from '@shapeable/ui';

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
  `,
});

// -------- Components -------->

const My = {
  Container: styled(PostBannerLayout)`${ContainerStyles}`,
};

export const PostLayout: EntityComponent<Post> = (props) => {
  const { className, entity, children } = props;

  return (
    <My.Container entity={entity} headerProps={{ variant: 'overlay' }} afterHeader={<SliceLayoutColorStrip />} className={className} />
  )
};